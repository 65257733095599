import React from "react";
import { Box, Button, Typography } from "@mui/material";
import "./index.scss";
import { useAuth0 } from "@auth0/auth0-react";

const TopHeader = ({ name }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <>
      <Box className="header-title-container">
        <Typography color="#ffffff" variant="h5">
          Welcome to {name}'s Zoom!
        </Typography>
        {!user && (
          <Box className="header-buttons-container">
            <Button className="header-btn-title">JOIN MY TEAM</Button>
            <Button className="header-btn-title">SHARE</Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TopHeader;
