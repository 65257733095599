import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-emnqbzns47fvm8yn.us.auth0.com"
      clientId="s5PsYyrUdpAdhxrAr2oAh7yRmaZY9KJO"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: ["https://dev-emnqbzns47fvm8yn.us.auth0.com/api/v2/"],
        scope: "openid profile email offline_access",
      }}
    >
      <BrowserRouter>
        <App></App>
      </BrowserRouter>
    </Auth0Provider>
    {/*  <BrowserRouter>
      <App></App>
    </BrowserRouter> */}
  </React.StrictMode>
);
