import React from "react";
import { useStopwatch } from "react-timer-hook";

const MyTimer = ({ expiryTimestamp }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useStopwatch({
    autoStart: true,
  });

  return (
    <div
      style={{
        textAlign: "left",
        display: "inline",
        width: "100%",
        height: "10%",
      }}
    >
      <div style={{ fontSize: "20px" }}>
        <span style={{ fontWeight: "bold" }}>Presentation Time: </span>
        <span>
          {hours <= 9 ? 0 : ""}
          {hours}
        </span>
        :
        <span>
          {minutes <= 9 ? 0 : ""}
          {minutes}
        </span>
        :
        <span>
          {seconds <= 9 ? 0 : ""}
          {seconds}
        </span>
      </div>
    </div>
  );
};

export default MyTimer;
