import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import "./navbar.scss";

export default function Navbar() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: "#000" }}>
        <Toolbar>
          <img src="https://i.imgur.com/ocLTWwv.png" width="100px" />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography
              className="navbar-item-plan"
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              Subscriptions
            </Typography>
            <Button
              color="inherit"
              className="btn-login-auth"
              onClick={() => loginWithRedirect()}
            >
              Sign-in
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
