import { Box, Button, Typography } from "@mui/material";
import React from "react";

export const ZoomReadyPresentationCard = (props) => {
  const { title, description } = props;
  return (
    <Box className="card-zrp-container">
      <Box className="image-card-container-zrp"></Box>
      <Box className="body-card-container-zrp">
        <Typography variant="h6" fontWeight="600">
          {title}
        </Typography>
        <Typography>{description}</Typography>
        <Button
          className="open-presentation-zrp"
          onClick={() => window.open("/host", "Host", "width=660,height=700")}
        >
          Open
        </Button>
      </Box>
    </Box>
  );
};
