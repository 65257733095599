import React from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { loginWithPopup, loginWithRedirect } = useAuth0();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    loginWithRedirect({
      login_hint: data.email,
      email: data.email,
      password: data.password,
      openUrl: window.location.origin,
    });
  };

  const onLoginWithGoogle = () => {
    loginWithPopup({ connection: "google-oauth2" });
  };

  const onLoginWithFacebook = () => {
    loginWithPopup({ connection: "facebook" });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          {...register("email", { required: true })}
        />
        {errors.email && <p>This field is required</p>}
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          {...register("password", { required: true })}
        />
        {errors.password && <p>This field is required</p>}
      </Form.Group>

      <Button variant="primary" type="submit">
        Log In
      </Button>

      <Button variant="primary" onClick={onLoginWithGoogle}>
        Log In with Google
      </Button>

      <Button variant="primary" onClick={onLoginWithFacebook}>
        Log In with Facebook
      </Button>
    </Form>
  );
};

export default Login;
