import React from "react";
import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, MenuItem, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import Header from "../../components/Header";
import EditIcon from "@mui/icons-material/Edit";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import SubTitle from "../../components/Subtitle";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Layout from "../global/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

function Row(props) {
  const { row } = props;
  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.bonusId}</TableCell>
        <TableCell align="right">{row.bonusTitle}</TableCell>
        <TableCell align="right">{row.commissionDate}</TableCell>
        <TableCell align="right">{row.amount}</TableCell>
        <TableCell align="right">{row.date}</TableCell>
        <TableCell align="right">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Released</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => {
                    return (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {historyRow.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.nodeId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.fullName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.commissionDate}
                        </TableCell>
                        <TableCell>{historyRow.released}</TableCell>
                        <TableCell align="right">
                          {historyRow.transaction}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bonusId: PropTypes.string.isRequired,
    bonusTitle: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        nodeId: PropTypes.number.isRequired,
        fullName: PropTypes.string.isRequired,
        commissionDate: PropTypes.string.isRequired,
        released: PropTypes.number.isRequired,
        transaction: PropTypes.string.isRequired,
      })
    ).isRequired,
    due: PropTypes.string.isRequired,
    paynow: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const Profile = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [userInfo, setUserInfo] = useState({});
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(true);
  const [users, setUsers] = useState();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [currentRefer, setCurrentRefer] = useState("");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleEnable = () => {
    setEnable(!enable);
  };
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    username: yup.string().required("First Name is a required field 🔴"),
    firstName: yup.string().required("First Name is a required field 🔴"),
    lastName: yup.string().required("Last Name is a required field 🔴"),
    emailAddress: yup
      .string()
      .email("Invalid email")
      .required(`Email is a required field ${"🔴"}`),
    contact: yup.string().matches(phoneRegExp, "Phone number is not valid 🔴"),
    customerType: yup.string().required("Customer Type is a required field 🔴"),
    referringCustomer: yup
      .string()
      .required("Referring Customer is a required field 🔴"),
    birthday: yup.string(),
    username: yup.string().required("Username is a required field 🔴"),
    city: yup.string(),
    state: yup.string(),
    zip: yup.number(),
    country: yup.string(),
  });

  const initialValues = {
    firstName: user?.given_name,
    lastName: user?.family_name,
    emailAddress: user?.email,
    contact: user?.phone_number,
    address1: userInfo.addresses?.length > 0 ? userInfo.addresses[0].line1 : "",
    address2: userInfo.addresses?.length > 0 ? userInfo.addresses[0].line2 : "",
    customerType: userInfo.customerType,
    referringCustomer: userInfo.sponsor,
    birthday: user?.birthdate,
    username: user?.nickname,
    city: userInfo.addresses?.length > 0 ? userInfo.addresses[0].city : "",
    state:
      userInfo.addresses?.length > 0 ? userInfo.addresses[0].stateCode : "",
    zip: userInfo.addresses?.length > 0 ? userInfo.addresses[0].zip : "",
    country:
      userInfo.addresses?.length > 0 ? userInfo.addresses[0].countryCode : "",
  };
  return (
    <Layout>
      <Box m="20px">
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header title="Profile Information" />
        </Box>

        <img className="show-profile-img" src={user?.picture} />
        <Typography variant="h4" className="name-main-profile">
          {user?.name}
        </Typography>
        {!loadingInfo && (
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <SubTitle subText="EDIT PERSONAL INFORMATION"></SubTitle>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="First Name*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={!!touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 4", fontSize: "14px" }}
                    className="field-input override-textfiled"
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Last Name*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    error={!!touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="EMAIL ADDRESS*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.emailAddress}
                    name="email"
                    error={!!touched.emailAddress && !!errors.emailAddress}
                    helperText={touched.emailAddress && errors.emailAddress}
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="PHONE NUMBER*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contact}
                    name="contact"
                    error={!!touched.contact && !!errors.contact}
                    helperText={touched.contact && errors.contact}
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    type="date"
                    size="small"
                    label="BIRTHDAY*"
                    defaultValue={new Date()}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.birthday}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="birthday"
                    error={!!touched.birthday && !!errors.birthday}
                    helperText={touched.birthday && errors.birthday}
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="USERNAME*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{ gridColumn: "span 4" }}
                    disabled={!enable}
                  />
                </Box>
              </form>
            )}
          </Formik>
        )}
        {loadingInfo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress
              style={{ color: "#babe49", width: "70px", height: "70px" }}
            />
          </Box>
        )}
        {/*  <Box sx={{ flexGrow: 1, height: "500px" }}>
    <Grid container spacing={2}>
      <Grid item xs={4} md={4}>
        <div className="phone-number-4 opensans-bold-cloud-16px">
          Bonus of {userInfo.firstName + " " + userInfo.lastName}
        </div>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Id</TableCell>
                <TableCell>Bonus Id</TableCell>
                <TableCell align="right">Bonus</TableCell>
                <TableCell align="right">HNT Due</TableCell>
                <TableCell align="right">HNT Pay Now</TableCell>
                <TableCell align="right">Com. Date</TableCell>
                <TableCell align="right">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bonusesUser.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Box> */}
      </Box>
    </Layout>
  );
};

export default withAuthenticationRequired(Profile);
