import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ZoomReadyPresentationCard } from "../zoomreadycard";

export const ZoomReadyPresentation = () => {
  return (
    <Box className="container-zrp">
      <Box className="header-title-zrp">
        <Typography className="title-zrp" variant="h5" fontWeight="600">
          Zoom Ready Presentation
        </Typography>
        <Button className="add-presentation-btn">ADD NEW</Button>
      </Box>
      <Box className="body-zrp">
        <ZoomReadyPresentationCard
          title="Virtual Skin"
          description="Step into your personal space for personalized service."
        />
      </Box>
    </Box>
  );
};
