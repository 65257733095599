import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import Modal from "react-modal";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "./App.css";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

Modal.setAppElement("#root");
function Products() {
  const [_id, setInputId] = useState(null);
  const [id_product, setInputIdProduct] = useState(null);
  const [name, setInputName] = useState(null);
  const [price, setInputPrice] = useState(null);
  const [url, setInputUrl] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [viewUpdate, setViewUpdate] = useState(false);

  const [products, setProducts] = useState([]);

  const domain_db = "https://backzoom.herokuapp.com";
  // const domain_db = "http://localhost:8000";

  useEffect(() => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    };

    fetch(`${domain_db}/products`, options)
    .then((response) => response.json())
    .then((result) => {
      setProducts(result);
    })
    .catch((error) => {
      console.log("error", error);
    });
  }, []);

  const saveProduct = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_product: id_product,
        name: name,
        price: price,
        url: url
      }),
    };
    fetch(`${domain_db}/products`, options)
      .then((response) => response.json())
      .then((result) => {
        setInputId("");
        setInputIdProduct("");
        setInputName("");
        setInputPrice("");
        setInputUrl("");
        setProducts(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const viewProduct = (product) => {
    setInputId(product._id);
    setInputIdProduct(product.id_product);
    setInputName(product.name);
    setInputPrice(product.price);
    setInputUrl(product.url);
    setOpenModal(true);
    setViewUpdate(true);
  }

  const updateProduct = () => {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_product: id_product,
        name: name,
        price: price,
        url: url
      }),
    };
    fetch(`${domain_db}/products/${_id}`, options)
      .then((response) => response.json())
      .then((result) => {
        setInputId("");
        setInputIdProduct("");
        setInputName("");
        setInputPrice("");
        setInputUrl("");
        setProducts(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div className="App">
        <br></br>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={(e) => {
              setOpenModal(true);
            }}
          >
            Create Product
          </Button>
        </Stack>
      </div>
      <div className="App">
        {
          !products.length
            ?
              <Typography variant="h4" sx={{ color: '#888888', fontSize: '19px', mt: 4 }}>You haven't had a product before.</Typography>
            : 
              <div style={{ height: '600px', overflowY: 'scroll' }}>
                {
                  products.map((item, index) =>
                    <Grid item md={12} sx={{ width: '100%' }}>
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar>
                            <img src={item.url} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.name}
                          secondary={`$${item.price}`}
                        />
                        <Button variant="contained" onClick={(e) => {
                          viewProduct(item);
                        }} >View Product</Button>
                    </ListItem>
                      <br></br>
                    </Grid>
                  )
                }
              </div>
        }
      </div>
      <div className="App">
        <Modal
          isOpen={openModal}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#e33f5b",
                }}
              >
                Product Information
              </h3>
            </div>
            <div className="content-form">
              <div>
                <div>
                  <label>ID</label>
                </div>
                <div>
                  <input
                    value={id_product || ""}
                    type="number"
                    onChange={(e) => setInputIdProduct(e.target.value)}
                    className="input-credentials"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label>Name</label>
                </div>
                <div>
                  <input
                    value={name || ""}
                    onChange={(e) => setInputName(e.target.value)}
                    className="input-credentials"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label>Price</label>
                </div>
                <div>
                  <input
                    value={price || ""}
                    type="number"
                    onChange={(e) => setInputPrice(e.target.value)}
                    className="input-credentials"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label>Url Image</label>
                </div>
                <div>
                  <input
                    value={url || ""}
                    onChange={(e) => setInputUrl(e.target.value)}
                    className="input-credentials"
                  />
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setInputId("");
                    setInputIdProduct("");
                    setInputName("");
                    setInputPrice("");
                    setInputUrl("");
                    setOpenModal(false);
                    setViewUpdate(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ display: viewUpdate ? 'none' : 'block' }}
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                    setViewUpdate(false);
                    saveProduct();
                  }}
                  disabled={!id_product}
                >
                  Save
                </button>
                <button
                  style={{ display: viewUpdate ? 'block' : 'none' }}
                  hidden={viewUpdate}
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                    setViewUpdate(false);
                    updateProduct();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Products;
