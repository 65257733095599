import React from "react";
import Card from "react-credit-cards";
import "./index.scss";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
} from "../../utils/paymentUtils";

import "react-credit-cards/es/styles-compiled.css";
import { Typography } from "@mui/material";

export default class PaymentForm extends React.Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    focused: "",
    formData: null,
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.form.reset();
  };

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <div key="Payment">
        <div className="App-payment">
          <form ref={(c) => (this.form = c)} onSubmit={this.handleSubmit}>
            <div className="form-group">
              <Typography variant="h5" fontWeight="bold">
                Card Number
              </Typography>
              <input
                type="tel"
                id="ccnumber"
                name="number"
                className="checkout-working-live-email-form-input"
                placeholder="0000 0000 0000 0000"
                pattern="[\d| ]{16,22}"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className="form-group">
              <Typography variant="h5" fontWeight="bold">
                Name
              </Typography>
              <input
                type="text"
                name="name"
                className="checkout-working-live-email-form-input"
                placeholder="Name"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className="row payment-values">
              <div className="col-6 payment-values-item">
                <Typography variant="h5" fontWeight="bold">
                  Valid Thru
                </Typography>
                <input
                  type="tel"
                  id="ccexp"
                  name="expiry"
                  className="checkout-working-live-email-form-input"
                  placeholder="00/00"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-6 payment-values-item">
                <Typography variant="h5" fontWeight="bold">
                  CVC
                </Typography>
                <input
                  type="password"
                  name="cvc"
                  id="cvv"
                  className="checkout-working-live-email-form-input"
                  placeholder="***"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <input type="hidden" name="issuer" value={issuer} />
          </form>
          <br></br>
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          {formData && (
            <div className="App-highlight">
              {formatFormData(formData).map((d, i) => (
                <div key={i}>{d}</div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
