import { Button } from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./index.scss";

export const FloatCloseBtn = (props) => {
  return (
    <Button className="float-close-iframe" onClick={props.onClick}>
      <CloseOutlinedIcon style={{ color: "#000" }} ></CloseOutlinedIcon>
    </Button>
  );
};
