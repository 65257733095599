import { useEffect, useState } from "react";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import CircularProgress from "@mui/material/CircularProgress";
import ScheduleIcon from "@mui/icons-material/Schedule";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Confetti from "react-confetti";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Skeleton from "@mui/material/Skeleton";
import "../../App.css";
import { Box, useTheme } from "@mui/material";
import Layout from "../global/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Carrusel from "../../components/Carrusel";
import "./index.scss";
import { CardMeeting } from "../../components/CardMeeting/CardMeeting";
import { CardStats } from "../../components/CardStats/CardStats";
import { ContentMeetings } from "../../components/ContentMeetings";
import { CardDetailMeeting } from "../../components/CardDetailMeeting";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// Utils

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#784af4",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#000 !important",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#333333 !important",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#DEDEDE",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#333333",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#333333 !important",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon fontSize="large" />,
    2: <DevicesOtherIcon fontSize="large" />,
    3: <ReduceCapacityIcon fontSize="large" />,
    4: <YoutubeSearchedForIcon fontSize="large" />,
    5: <InsertInvitationIcon fontSize="large" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "MEETING SET UP",
  "CHOOSE PRODUCT VIEW",
  "ADD CO-HOSTS",
  "REVIEW CRITERIA",
  "CREATE INVITE LINK",
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const Dashboard = () => {
  // auth
  const { user, getAccessTokenSilently } = useAuth0();

  const [idUser, setIdUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [urlStore, setUrlStore] = useState(null);
  const [topic, setInputTopic] = useState(null);
  const [inputDesc, setInputDesc] = useState("");
  const [inputDate, setInputDate] = useState(new Date());
  const [inputTime, setInputTime] = useState(new Date());
  const [modalStepTwo, setModalStepTwo] = useState(false);
  const [modalMiddleStep, setModalMiddleStep] = useState(false);
  const [modalTeam, setModalTeam] = useState(false);
  const [modalReview, setModalReview] = useState(false);
  const [chooseOption, setChooseOption] = useState(0);
  const [filterProducts, setFilterProducts] = useState(null);
  const [teamSelected, setTeamSelected] = useState([]);
  const [loadingInvitation, setLoadingInvitation] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [idMeeting, setIDMeeting] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(null);
  const [items, setitems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showDate, setShowDate] = useState(new Date());
  const [showTime, setShowTime] = useState(new Date());
  const [createdMeeting, setCreateMeeting] = useState(0);
  const [nextMeeting, setNextMeeting] = useState(null);
  const [copyFirst, setCopyFirst] = useState(false);
  const [copySecond, setCopySecond] = useState(false);
  const [copyThird, setCopyThird] = useState(false);
  const [invite, setInvite] = useState(false);
  const [emailInvitation, setEmailInvitation] = useState("");
  const [nameInvitation, setNameInvitation] = useState("");
  const [referralInvitation, setReferralINvitation] = useState("");
  const [invitationSended, setInvitationSended] = useState(false);
  const [saveMeetingInfo, setSaveMeetingInfo] = useState(null);

  const team = [
    {
      id: 1,
      name: "Nancy Baker",
      smallThumbnailUrl:
        "https://img.freepik.com/free-photo/portrait-happy-young-woman-looking-camera_23-2147892777.jpg?w=2000",
    },
    {
      id: 2,
      name: "Tom Reeve",
      smallThumbnailUrl:
        "https://media.istockphoto.com/id/1300512215/photo/headshot-portrait-of-smiling-ethnic-businessman-in-office.jpg?b=1&s=170667a&w=0&k=20&c=TXCiY7rYEvIBd6ibj2bE-VbJu0rRGy3MlHwxt2LHt9w=",
    },
    {
      id: 3,
      name: "Gloria McKenny",
      smallThumbnailUrl:
        "https://www.upthereeverywhere.com/hubfs/2020_People/LizR_portrait-square%20cr.jpeg",
    },
  ];

  const [meetings, setMeetings] = useState([]);

  const zoomDomain = "https://meetingssellwithzoom.herokuapp.com";
  const backDomain = "https://backzoom.herokuapp.com";
  const [valueTab, setValueTab] = useState("1");

  const handleMeeting = () => {
    setOpenModal(true);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChange = (newValue) => {
    setInputDate(newValue);
  };

  const handleChangeTime = (newValue) => {
    setInputTime(newValue);
  };

  const updateProductList = (id) => {
    if (!productList.includes(id)) {
      setProductList((productList) => [...productList, id]);
    } else {
      setProductList([]);
      productList.map((productID) => {
        if (productID != id) {
          setProductList((productList) => [...productList, productID]);
        }
      });
    }
  };

  useEffect(() => {
    console.log(user);
  }, []);

  const sendInvitation = async () => {
    setLoadingInvitation(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify({
        name: nameInvitation,
        email: emailInvitation,
        referarstring: referralInvitation,
      }),
    };

    fetch("https://companduser.herokuapp.com/addteam", options)
      .then((res) => res.json())
      .then((res) => {
        setLoadingInvitation(false);
        setInvitationSended(true);
      })
      .catch((err) => {
        console.log(err);
        setLoadingInvitation(false);
      });
  };

  const updateTeamSelected = (id) => {
    if (!teamSelected.includes(id)) {
      setTeamSelected((teamSelected) => [...teamSelected, id]);
    } else {
      setTeamSelected([]);
      teamSelected.map((productID) => {
        if (productID != id) {
          setTeamSelected((teamSelected) => [...teamSelected, productID]);
        }
      });
    }
  };

  useEffect(() => {
    console.log(user);
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer public_z7gaHztX8yD8gwEg8RH2MEzAT65xpFYC",
      },
    };

    fetch(`https://app.ecwid.com/api/v3/84085268/products`, options)
      .then((response) => response.json())
      .then((result) => {
        setitems(result.items);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    const dateShow = new Date(inputDate).toString().split(" ").slice(0, 4);
    const concatDate =
      dateShow.slice(0, 3).join(" ") + ", " + dateShow.slice(3, 4).join(" ");
    setShowDate(concatDate);
    const dateStart = new Date(inputDate).toLocaleTimeString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    setShowTime(
      [dateStart.split(":")[0], dateStart.split(":")[1]].join(":") +
        " " +
        dateStart.split(" ")[1] +
        " " +
        Intl.DateTimeFormat().resolvedOptions().timeZone?.split("_").join(" ")
    );
  }, [inputTime, inputDate]);

  useEffect(() => {
    setIdUser(process.env.REACT_APP_IDUSERSTEVE);
    setUserId(process.env.REACT_APP_IDUSERSTEVE);
  }, []);

  useEffect(() => {
    const loadMeetings = async () => {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      };

      fetch(
        `https://backzoom.herokuapp.com/meetingDBByUser/${user?.sub}`,
        options
      )
        .then((response) => response.json())
        .then((result) => {
          //setMeetings(result?.meetings);
          setMeetings(result);
          setNextMeeting(result[0]);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    user?.sub && loadMeetings();
  }, [createdMeeting]);

  const openMeeting = () => {
    let url = document.getElementById("txtLinkMeeting").value;
    window.open(
      `${window.location.origin}/${idMeeting}${
        url ? `?loadIframe=` + url : ""
      }`,
      "_blank"
    );
  };

  const copyInvitation = (id) => {
    navigator.clipboard
      .writeText(
        `${window.location.origin}/${idMeeting}${
          url ? `?loadIframe=` + url : ""
        }`
      )
      .then(() => {
        setCopied(true);
        // console.log("Text copied to clipboard...");
      });
  };

  const handleInvite = () => {
    setInvite(true);
  };

  const generateMeeting = async () => {
    setLoading(true);
    let userId = process.env.REACT_APP_IDUSERSTEVE;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify({
        id_user: user.sub,
        start_time: `${inputDate}T${inputTime}Z`,
        topic: topic,
        description: inputDesc,
        settings: {},
        product_ids: productList.join(","),
        url_store: url,
        cohost: ["mateoramos1997@gmail.com"],
      }),
    };

    await fetch(`${zoomDomain}/meeting`, options)
      .then((response) => response.json())
      .then((result) => {
        console.log("Generate Meeting: ", result);
        setIDMeeting(result.meeting_id);
        setLoading(false);
        setCreateMeeting(createdMeeting + 1);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  return (
    <>
      <Layout>
        <Box className="dashboard-container">
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            gap="15px"
          >
            {/* <DynamicHeader title={`Hi`} subtitle="Welcome to your Admin Panel" /> */}
            <Box width="100%" className="content-slider">
              <Carrusel
                navButtonsProps={{
                  // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                  style: {
                    backgroundColor: "#red",
                    borderRadius: 0,
                  },
                }}
                navButtonsWrapperProps={{
                  // Move the buttons to the bottom. Unsetting top here to override default style.
                  style: {
                    bottom: "0",
                    backgroundColor: "#red",
                    top: "unset",
                  },
                }}
              ></Carrusel>
              <div className="slider-content-sub">
                <div>
                  <h4 style={{ fontWeight: "bold", textAlign: "start" }}>
                    Hi, {user?.name}
                  </h4>
                </div>
                <div className="sub-text-slider">
                  <span style={{ fontSize: "13px", textAlign: "left" }}>
                    Welcome, start selling with Zoom shopping today!
                  </span>
                </div>
              </div>
            </Box>

            <Box className="tab-meetings-dashboard">
              <TabContext value={valueTab ? valueTab : "1"}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                    sx={{
                      "& button": {
                        borderTopLeftRadius: 2,
                        borderTopRightRadius: 2,
                      },
                      "& button:hover": { backgroundColor: "#D9F1F7" },
                      "& button:focus": { backgroundColor: "#D9F1F7" },
                      "& button.Mui-selected": {
                        backgroundColor: "#D9F1F7",
                        color: "#000000 !important",
                      },
                      "& .MuiTabs-flexContaine": {
                        height: "50px",
                      },
                      "& .MuiButtonBase-root": {
                        fontSize: "12px !important",
                        width: "200px",
                        height: "50px",
                      },
                    }}
                  >
                    <Tab
                      label={`Upcoming Meetings`}
                      value="1"
                      style={{
                        width: "200px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#06213b",
                        textAlign: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      focusVisibleClassName="focus-tab"
                    ></Tab>
                    <Tab
                      label={`Previous Meetings`}
                      value="2"
                      style={{
                        width: "200px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#06213b",
                        textAlign: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      focusVisibleClassName="focus-tab"
                    />
                    {/* <Tab
                label="Company Commissions"
                value="3"
                style={{
                  width: "33%",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#06213b",
                  textAlign: "center",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                focusVisibleClassName="focus-tab"
              /> */}
                  </TabList>
                </Box>
                <TabPanel value="1" className="upcoming-meetings-container">
                  <Box m="0 0 0 0" height="auto">
                    <ContentMeetings
                      onClick={handleMeeting}
                      onInvite={handleInvite}
                    ></ContentMeetings>
                    {meetings?.map((meet, index) => {
                      const dateStart = new Date(
                        meet?.start_time
                      ).toLocaleTimeString("en-US", {
                        timeZone: meet?.time_zone,
                      });
                      return (
                        <CardDetailMeeting
                          key={index}
                          startDate={new Date(meet?.start_time).toDateString()}
                          meetingTopic={meet?.topic}
                          startTime={
                            [
                              dateStart.split(":")[0],
                              dateStart.split(":")[1],
                            ].join(":") +
                            " " +
                            dateStart.split(" ")[1] +
                            " " +
                            meet?.time_zone?.split("_").join(" ")
                          }
                          meetingID={
                            meet?.join_url
                              ?.split("?")[0]
                              .split("/")
                              .reverse()[0]
                          }
                        ></CardDetailMeeting>
                      );
                    })}
                  </Box>
                </TabPanel>
                <TabPanel value="2">
                  <Box m="0 0 0 0" height="auto">
                    <h1>SOON</h1>
                  </Box>
                </TabPanel>
                {/* <TabPanel value="3">
            {" "}
            <h1>Coming Soon</h1>
          </TabPanel> */}
              </TabContext>
            </Box>
          </Box>
          <Box className="right-side-cards">
            <CardMeeting details={nextMeeting} />
            <CardStats
              title="Total Meeting Sales"
              totalValue="$632.00"
              details="VIEW ALL SALES"
            />
            <CardStats
              title="Product Sold"
              totalValue="123"
              details="VIEW PRODUCTS"
            />
          </Box>
        </Box>
      </Layout>
      {/*  First Step  */}
      <div className="App">
        <Modal
          isOpen={openModal}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div className="first-step-content">
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#202020",
                }}
              >
                Meeting Set Up
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={0}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              <div className="container-input-create">
                <div style={{}}>
                  <Typography variant="h6" fontWeight="bold">
                    Topic / Title
                  </Typography>
                </div>
                <div className="container-input-create">
                  <input
                    onChange={(e) => setInputTopic(e.target.value)}
                    className="input-credentials"
                    defaultValue={topic ? topic : ""}
                    placeholder="Example: Let's talk about skin care"
                  />
                </div>
                {/* <div>
                  <label>Store URL</label>
                </div>
                <div>
                  <input
                    onChange={(e) => setURL(e.target.value)}
                    className="input-credentials"
                  />
                </div> */}
              </div>
              <div className="container-input-create">
                <div className="container-input-create">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    className="container-input-create"
                  >
                    <Stack spacing={0.5} className="container-input-create">
                      <Typography variant="h6" fontWeight="bold">
                        Date
                      </Typography>

                      {!isMobile ? (
                        <DesktopDatePicker
                          id="date-select-wk"
                          inputFormat="MM/DD/YYYY"
                          minDate={new Date()}
                          value={inputDate}
                          className="input-credentials container-input-create"
                          onChange={handleChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "tt.mm.jjjj",
                              }}
                            />
                          )}
                        />
                      ) : (
                        <MobileDatePicker
                          inputFormat="MM/DD/YYYY"
                          value={inputDate}
                          className="input-credentials container-input-create"
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                      <Typography variant="h6" fontWeight="bold">
                        Time
                      </Typography>

                      <TimePicker
                        className="input-credentials"
                        value={inputTime}
                        onChange={handleChangeTime}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="container-input-create">
                <div>
                  <Typography variant="h6" fontWeight="bold">
                    Description
                  </Typography>
                </div>
                <div>
                  <textarea
                    type="text"
                    onChange={(e) => setInputDesc(e.target.value)}
                    className="input-credentials input-description new-text-area"
                    defaultValue={inputDesc ? inputDesc : ""}
                    placeholder="Example: How to improve your night time skin care regime and more!"
                  />
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(false);
                    setModalMiddleStep(true);
                  }}
                  disabled={!topic}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
      {/*  Second Step  */}
      <div className="App">
        <Modal
          isOpen={modalMiddleStep}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#202020",
                }}
              >
                Choose Product View
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={1}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ padding: "10px" }}
              >
                Choose product view
              </Typography>
              <div className="content-choose-cards">
                <div className="choose-card" onClick={() => setChooseOption(1)}>
                  <div className="choose-card-header">
                    <img
                      className="image-header"
                      src="https://images.unsplash.com/photo-1504270997636-07ddfbd48945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
                    />
                  </div>
                  <div className="choose-card-body">
                    <h4 className="choose-card-title">URL Store</h4>
                    <p style={{ fontSize: "10px" }}>
                      Selecting URL Store you can view the products of any store
                      using the URL of the store you want to access during the
                      meeting
                    </p>
                  </div>
                  {chooseOption == 1 ? (
                    <CheckCircleIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  )}
                </div>
                <div className="choose-card" onClick={() => setChooseOption(2)}>
                  <div className="choose-card-header">
                    <img
                      className="image-header"
                      src="https://images.unsplash.com/photo-1522204523234-8729aa6e3d5f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                    />
                  </div>
                  <div className="choose-card-body">
                    <h4 className="choose-card-title">Products</h4>
                    <p style={{ fontSize: "10px" }}>
                      Select the products that you want people in the meeting to
                      be able to buy.
                    </p>
                  </div>
                  {chooseOption == 2 ? (
                    <CheckCircleIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon
                      sx={{
                        position: "relative",
                        top: "0",
                        left: "94%",
                        fontSize: "large",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setOpenModal(true);
                    setModalMiddleStep(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalStepTwo(true);
                    setModalMiddleStep(false);
                  }}
                  disabled={chooseOption == 0}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
      {/*  Third Step  */}
      <div className="App">
        <Modal
          isOpen={modalStepTwo}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                Search Products
              </h3>
            </div>
            <div>
              <Stepper
                alternativeLabel
                activeStep={1}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="content-form">
              {chooseOption == 1 && (
                <div className="select-products-container">
                  <div>
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      URL Store
                    </Typography>
                    <input
                      placeholder="Add an URL Store"
                      className="checkout-working-live-email-form-input"
                      onChange={(e) => setUrlStore(e.target.value)}
                    />
                  </div>
                </div>
              )}

              {chooseOption == 2 && (
                <div className="select-products-container">
                  <div>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      Choose the products
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        type="search"
                        className="input-credentials"
                        placeholder="Search Catalog"
                        onChange={(e) => setFilterProducts(e.target.value)}
                      />
                    </div>
                    <div className="content-product-list">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        {items?.map((item) => {
                          return (
                            <ListItem>
                              <Checkbox
                                key={item.id}
                                onClick={() => updateProductList(item.id)}
                                checked={productList.includes(item.id)}
                              />
                              <div style={{ padding: "5px" }}>
                                <img
                                  src={item.smallThumbnailUrl}
                                  width="60px"
                                  style={{
                                    borderRadius: "3px",
                                    border: "1px solid #DEDEDE",
                                  }}
                                />
                              </div>
                              <ListItemText
                                primary={item.name}
                                secondary={`$${item.price}`}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                </div>
              )}

              <div className="step-btn-content">
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalMiddleStep(true);
                    setModalStepTwo(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="btn-personalized"
                  onClick={(e) => {
                    setModalStepTwo(false);
                    setModalTeam(true);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
      {/*  Add CO HOSTS  */}
      <div className="App">
        <Modal
          isOpen={modalTeam}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#333333",
              }}
            >
              Add Co-Host (Optional)
            </h3>
          </div>
          <div>
            <Stepper
              alternativeLabel
              activeStep={2}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className="content-form" style={{ width: "100%" }}>
            <div className="select-products-container">
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    type="search"
                    className="input-credentials"
                    placeholder="Search My Team"
                    onChange={(e) => setFilterProducts(e.target.value)}
                  />
                </div>
                <div className="content-product-list">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {team?.map((item) => {
                      return (
                        <ListItem>
                          <Checkbox
                            key={item.id}
                            onClick={() => updateTeamSelected(item.id)}
                            checked={teamSelected.includes(item.id)}
                          />
                          <div style={{ padding: "5px" }}>
                            <img
                              src={item.smallThumbnailUrl}
                              width="60px"
                              style={{
                                borderRadius: "3px",
                                border: "1px solid #DEDEDE",
                              }}
                            />
                          </div>
                          <ListItemText
                            sx={{ fontSize: "12px" }}
                            primary={item.name}
                            secondary={`Team Member`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>

            <div className="step-btn-content">
              <button
                className="btn-personalized"
                onClick={(e) => {
                  setModalStepTwo(true);
                  setModalTeam(false);
                }}
              >
                Back
              </button>
              <button
                className="btn-personalized"
                onClick={(e) => {
                  setModalTeam(false);
                  setModalReview(true);
                }}
              >
                Next
              </button>
              <button
                className="btn-personalized-skip"
                onClick={(e) => {
                  setModalTeam(false);
                  setModalReview(true);
                }}
                disabled={chooseOption == 1 && !url}
              >
                Skip
              </button>
            </div>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>
      {/*  Review Details  */}
      <div className="App">
        <Modal
          isOpen={modalReview}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#333333",
              }}
            >
              Review
            </h3>
          </div>
          <div>
            <Stepper
              alternativeLabel
              activeStep={3}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div
            className="content-form review-meeting-container"
            style={{ width: "80%", borderRadius: "5px" }}
          >
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                padding: "10px",
              }}
            >
              Meeting Information
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {topic}
            </Typography>
            <div className="select-info-meeting-container">
              <Box className="meeting-info-header">
                <CalendarMonthIcon fontSize="large" />
                <Typography variant="h5">{showDate}</Typography>
                <ScheduleIcon fontSize="large" sx={{ color: "#73CCE9" }} />
                <Typography variant="h5" color="#73CCE9">
                  {showTime}
                </Typography>
              </Box>
              <Box>
                <Box className="meeting-info-check">
                  <Checkbox checked={url} />
                  <Typography variant="p" fontSize="14px">
                    Store URL- Entire Catalog
                  </Typography>
                </Box>
                <Box className="meeting-info-check">
                  <Checkbox checked={items.length > 0} />
                  <Typography variant="p" fontSize="14px">
                    Individual Products
                  </Typography>
                </Box>
                <Box className="images-info-container">
                  {items?.map((item) => {
                    if (productList?.includes(item?.id))
                      return (
                        <Box>
                          <a title={item.name}>
                            <img
                              className="image-info-meeting"
                              src={item.smallThumbnailUrl}
                              alt={item.name}
                              width="60px"
                            />
                          </a>
                        </Box>
                      );
                  })}
                </Box>
                <Box className="meeting-info-check">
                  <Checkbox checked={teamSelected.length > 0} />
                  <Typography variant="p" fontSize="14px">
                    Co-Hosts: Nancy Baker, Tom Reeve
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>
          <div className="step-btn-content">
            <button
              className="btn-personalized"
              onClick={(e) => {
                setModalTeam(true);
                setModalReview(false);
              }}
            >
              Back
            </button>
            <button
              className="btn-personalized"
              onClick={(e) => {
                generateMeeting();
                setModalReview(false);
                setConfirmModal(true);
              }}
            >
              Create Link
            </button>
          </div>

          {/* <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button> */}
        </Modal>
      </div>

      {/*  Generate Meeting  */}
      <div className="App">
        <Modal
          isOpen={confirmModal}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <div>
              <h3
                style={{
                  fontWeight: "bold",
                  color: "#202020",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                Create Invite Link
              </h3>
            </div>
            <Stepper
              alternativeLabel
              activeStep={4}
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="content-form">
              <Box className="content-form-header">
                <Typography variant="h3" fontWeight="bold" textAlign="center">
                  {topic}
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  Your Zoom Shooping meeting has been created!{" "}
                </Typography>
              </Box>
              <Box className="shared-zoom-meeting">
                <Typography
                  className="shared-zoom-meeting-item"
                  variant="h5"
                  fontWeight="bold"
                >
                  Google Calendar
                </Typography>
                <Typography
                  className="shared-zoom-meeting-item"
                  variant="h5"
                  fontWeight="bold"
                >
                  ICAL
                </Typography>
                <Typography
                  className="shared-zoom-meeting-item"
                  variant="h5"
                  fontWeight="bold"
                >
                  Microsoft Outlook
                </Typography>
                <Typography
                  className="shared-zoom-meeting-item"
                  variant="h5"
                  fontWeight="bold"
                >
                  Add to Calendly
                </Typography>
              </Box>
              <Box className="form-inline">
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url : ""
                        }`}
                      />
                      <Button
                        className="start-meeting-tab btn-width-start"
                        onClick={(e) => {
                          copyInvitation();
                        }}
                      >
                        {copied ? "Copied" : "COPY HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
              </Box>
              <Box className="form-inline form-inline-container">
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url + "" : "?rep=nacybaker"
                        }`}
                      />
                      <Button
                        className="btn-width-start-co"
                        onClick={(e) => {
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${idMeeting}${
                                url ? `?loadIframe=` + url : "?rep=nacybaker"
                              }`
                            )
                            .then(() => {
                              setCopyFirst(true);
                              // console.log("Text copied to clipboard...");
                            });
                        }}
                      >
                        {copyFirst ? "COPIED" : "COPY CO-HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url : "?rep=tomreev"
                        }`}
                      />
                      <Button
                        className="btn-width-start-co"
                        onClick={(e) => {
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${idMeeting}${
                                url ? `?loadIframe=` + url : "?rep=nacybaker"
                              }`
                            )
                            .then(() => {
                              setCopySecond(true);
                              // console.log("Text copied to clipboard...");
                            });
                        }}
                      >
                        {copySecond ? "COPIED" : "COPY CO-HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
                <Box className="form-inline-link">
                  {!loading && (
                    <>
                      <input
                        type="text"
                        className="input-credentials share-meeting shared-meeting-input"
                        id="txtLinkMeeting"
                        disabled={true}
                        placeholder={`${window.location.origin}/${idMeeting}${
                          url ? `?loadIframe=` + url : "?rep=terryroan"
                        }`}
                      />
                      <Button
                        className="btn-width-start-co"
                        onClick={(e) => {
                          navigator.clipboard
                            .writeText(
                              `${window.location.origin}/${idMeeting}${
                                url ? `?loadIframe=` + url : "?rep=nacybaker"
                              }`
                            )
                            .then(() => {
                              setCopyThird(true);
                              // console.log("Text copied to clipboard...");
                            });
                        }}
                      >
                        {copyThird ? "COPIED" : "COPY CO-HOST LINK"}
                      </Button>
                    </>
                  )}
                  {loading && (
                    <Skeleton variant="rounded" width="100%" height={30} />
                  )}
                </Box>
              </Box>
              <div className="content-btn-down">
                <Button
                  className="btn-width-start-exit"
                  onClick={(e) => {
                    openMeeting();
                  }}
                >
                  SHARE INVITE
                </Button>
                <Button
                  className="btn-width-start-exit"
                  onClick={(e) => {
                    setConfirmModal(false);
                    window.location.reload();
                  }}
                >
                  GO TO DASHBOARD
                </Button>
                <Button
                  className="start-meeting-tab btn-width-start"
                  onClick={(e) => {
                    openMeeting();
                  }}
                >
                  START MEETING NOW
                </Button>
              </div>
            </div>
          </div>

          <button
            className="close-modal"
            onClick={() => {
              setConfirmModal(false);
              window.location.reload();
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>

      {/*  Invite  */}
      <div className="App">
        <Modal
          isOpen={invite}
          // onRequestClose={() => setConfirmModal(false)}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after-max-invite",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          {!invitationSended && (
            <div>
              <div>
                <h2
                  style={{
                    fontWeight: "bold",
                    color: "#202020",
                    padding: "30px 30px 15px 30px",
                    textAlign: "center",
                  }}
                >
                  Invite to my Team
                </h2>
              </div>
              <div className="content-form">
                <Box className="content-form-header">
                  <Typography
                    variant="h5"
                    fontWeight="500"
                    textAlign="center"
                    padding="0px 10px 10px 10px"
                  >
                    Here you can invite more people to be part of your team by
                    completing the following fields.
                  </Typography>
                </Box>
                <Box style={{ width: "80%" }}>
                  <Typography variant="h5" fontWeight="bold">
                    Full Name
                  </Typography>
                  <input
                    type="text"
                    className="checkout-working-live-email-form-input"
                    placeholder="Enter Full Name"
                    onChange={(e) => setNameInvitation(e.target.value)}
                  />
                </Box>
                <Box style={{ width: "80%" }}>
                  <Typography variant="h5" fontWeight="bold">
                    Email
                  </Typography>
                  <input
                    type="email"
                    className="checkout-working-live-email-form-input"
                    placeholder="Enter Email"
                    onChange={(e) => setEmailInvitation(e.target.value)}
                  />
                </Box>
                <Box style={{ width: "80%" }}>
                  <Typography variant="h5" fontWeight="bold">
                    Referral Indentifier
                  </Typography>
                  <input
                    type="email"
                    className="checkout-working-live-email-form-input"
                    placeholder="Company Referral Indentifier"
                    onChange={(e) => setReferralINvitation(e.target.value)}
                  />
                </Box>
                <div className="content-btn-down">
                  <Button
                    className="btn-width-start-exit"
                    onClick={(e) => {
                      sendInvitation();
                    }}
                    disabled={
                      !nameInvitation || !emailInvitation || !referralInvitation
                    }
                  >
                    {loadingInvitation ? (
                      <CircularProgress sx={{ color: "#B11E55" }} />
                    ) : (
                      "SEND INVITATION"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {invitationSended && (
            <>
              <Confetti numberOfPieces={70} tweenDuration={50}></Confetti>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div>
                  <h2
                    style={{
                      fontWeight: "bold",
                      color: "#202020",
                      padding: "0px 30px 10px 30px",
                      textAlign: "center",
                    }}
                  >
                    Invitation sent! 🎉
                  </h2>
                </div>
                <div className="content-form">
                  <Box className="content-form-header">
                    <Typography
                      variant="h5"
                      fontWeight="500"
                      textAlign="center"
                      padding="0px 20px 20px 20px"
                    >
                      The invitation for <b>{nameInvitation}</b> has been sent.
                      The invitation was sent to the email{" "}
                      <b>{emailInvitation}</b>
                    </Typography>
                  </Box>
                </div>
              </div>
            </>
          )}

          <button
            className="close-modal"
            onClick={() => {
              setInvite(false);
              setEmailInvitation("");
              setNameInvitation("");
              setReferralINvitation("");
              setInvitationSended(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Dashboard);
