import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import ItemPresentation from "../ItemPresentation";
import { useRef } from "react";
import { Button, Box, Typography } from "@mui/material";

const beautyProducts = [
  "Take care of your skin with our antioxidant facial serum. Designed to reduce the appearance of wrinkles, this nutrient-rich serum hydrates and protects your skin from signs of aging.",
  "Get a natural glow with our liquid foundation. Easy to apply and lightweight, our liquid foundation formula will give you a fresh and radiant look all day.",
  "Want to achieve flawless skin? Try our coconut oil and aloe vera facial cleanser. It deeply cleanses and softens your skin without leaving residue or dryness.",
  "Give your eyes a rejuvenated look with our under-eye concealer. With a full-coverage formula, it helps you conceal those dark circles for a fresh and luminous appearance.",
  "Worried about fine lines and wrinkles? Try our eye serum. Formulated with natural ingredients, it helps to hydrate, reduce dark circles, and smooth out fine lines.",
  "Keep your lips soft and nourished with our hydrating lip balm. Made with organic ingredients and essential oils, this balm is perfect for keeping your lips healthy and smooth.",
  "Give your hair a fuller and voluminous look with our collagen shampoo and conditioner. Infused with collagen and biotin, it helps to improve the strength and texture of your hair.",
  "Get rid of dark spots and discoloration with our skin whitening serum. With a gentle yet effective formula, it helps to reduce the appearance of dark spots and give your skin a more even look.",
  "Want a natural and long-lasting makeup look? Try our matte finish compact powder. With a lightweight and easy-to-apply formula, it helps to keep your skin looking fresh and matte all day.",
];

export const SliderHost = ({ sliders, activeControls, activeNavBtn }) => {
  const [currentValue, setCurrentValue] = useState(0);

  return (
    <Box>
      <Carousel
        index={currentValue}
        autoPlay={false}
        navButtonsAlwaysVisible={false}
        NavButton={({ onClick, className, style, next, prev }) => {
          // Other logic

          return <></>;
        }}
      >
        {sliders.map((item) => (
          <ItemPresentation key={item.id} item={item} />
        ))}
      </Carousel>
      <Box className="btn-host-controls-container">
        {activeControls && (
          <Button
            className="btn-host-controls"
            onClick={() => {
              if (currentValue - 1 < 0) {
                localStorage.setItem("actual", 8);
                setCurrentValue(8);
              } else {
                localStorage.setItem("actual", currentValue - 1);
                setCurrentValue(currentValue - 1);
              }
            }}
          >
            Previous
          </Button>
        )}
        {activeControls && (
          <Button
            className="btn-host-controls"
            onClick={() => {
              if (currentValue + 1 > 8) {
                localStorage.setItem("actual", 0);
                setCurrentValue(0);
              } else {
                localStorage.setItem("actual", currentValue + 1);
                setCurrentValue(currentValue + 1);
              }
            }}
          >
            Next
          </Button>
        )}
        {activeControls && (
          <Button
            className="btn-host-controls-participant"
            onClick={() => {
              window.open(
                "/presentation",
                "Presentation",
                "width=800,height=600"
              );
              localStorage.setItem("actual", currentValue);
            }}
          >
            Open Participant View
          </Button>
        )}
      </Box>
      {activeControls && (
        <Box className="note-content-presentation">
          <Typography variant="h6">
            <span sx={{ fontWeight: "bold" }}>Notes:</span>{" "}
            {beautyProducts[currentValue]}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
