import React, { useState } from "react";
import { presentation } from "../../data/mockData";
import { Typography, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { SliderHost } from "../../components/SliderHost";
import "./index.css";
import MyTimer from "../myTimer";

const Host = (props) => {
  const [currentSlide, setCurrentSlide] = useState("");
  const { user } = useAuth0();
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer

  return (
    <Box className="presentation-container">
      <Box className="presentation-sub-container">
        <SliderHost
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "#red",
              borderRadius: 0,
              top: "unset",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              bottom: "0",
              backgroundColor: "#red",
              top: "unset",
            },
          }}
          sliders={presentation}
          activeControls={true}
        />
      </Box>
      <MyTimer expiryTimestamp={time} />
    </Box>
  );
};

export default Host;
