import { Box } from "@mui/material";

const SubTitle = (props) => {
  const { subText, bold } = props;
  return (
    <Box
      style={{
        height: "20px",
        display: "flex",
        marginBottom: "5px",
      }}
    >
      <h4 style={{ color: "#000", fontWeight: bold }}>{subText}</h4>
    </Box>
  );
};

export default SubTitle;
