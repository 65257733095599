import React, { useState } from "react";
import { presentation } from "../../data/mockData";
import { Typography, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { SliderPresentation } from "../../components/SliderPresentation";
import "./index.css";

const Presentation = (props) => {
  const [currentSlide, setCurrentSlide] = useState("");
  const { user } = useAuth0();
  return (
    <Box className="presentation-container">
      <SliderPresentation
        className="container-slider-presentation"
        navButtonsAlwaysVisible={false}
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            backgroundColor: "#red",
            borderRadius: 0,
            top: "unset",
          },
        }}
        navButtonsWrapperProps={{
          // Move the buttons to the bottom. Unsetting top here to override default style.
          style: {
            bottom: "0",
            backgroundColor: "#red",
            top: "unset",
          },
        }}
        sliders={presentation}
      />
    </Box>
  );
};

export default Presentation;
