import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Create from "./create_meeting";
import WKMeeting from "./WKMeeting";
import Products from "./Products";
//import Socket from "./Socket";
import Dashboard from "./scenes/dashboard";
import Payments from "./scenes/payments";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";
import { Plan } from "./scenes/plan";
import Participant from "./scenes/participant";
import Presentation from "./scenes/presentation";
import Profile from "./scenes/profile";
import Host from "./scenes/host";
import Login from "./scenes/login";
import "./App.css";

function App() {
  const [slide, setSlide] = useState(0);

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/team" element={<Team />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/invoices" element={<Invoices />} />
      <Route path="/form" element={<Form />} />
      <Route path="/login" element={<Login />} />
      <Route path="/bar" element={<Bar />} />
      <Route path="/pie" element={<Pie />} />
      <Route path="/line" element={<Line />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/payment" element={<Payments />} />
      <Route path="/geography" element={<Geography />} />
      <Route path="/create" element={<Create />} />
      <Route path="/profile" element={<Profile />} />
      <Route path=":meetingNumber" element={<WKMeeting />}></Route>
      <Route path="/products" element={<Products />} />
      <Route
        path="/presentation"
        element={<Presentation setSlide={setSlide} />}
      />
      <Route path="/host" element={<Host setSlide={setSlide} />} />
      <Route path="/participant" element={<Participant slide={slide} />} />
    </Routes>
  );
}

export default App;
