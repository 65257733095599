import React from "react";
import { Paper, Button } from "@mui/material";

const Item = ({ item }) => {
  return (
    <Paper>
      <img
        src={item.reference}
        width="100%"
        height="300"
        style={{ objectFit: "cover" }}
      />
    </Paper>
  );
};

export default Item;
