import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      className="footer-main"
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "30px",
        background: colors.primary[400],
      }}
    >
      <Typography variant="h6" color={colors.grey[100]}>
        Sell With ZOOM | All rights reserved. | Copyright © 2022.
      </Typography>
    </Box>
  );
};

export default Footer;
