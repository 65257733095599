import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import CollectJSSection from "./CollectJsSection";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { useCreateOrder } from "../../hooks/useOrder";

class InlineCartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.name,
      lastName: this.props.name,
      amount: this.props.amount,
      isSubmitting: false,
      alertMessage: "",
      isLoading: false,
    };
    this.setState = this.setState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.finishSubmit = this.finishSubmit.bind(this);
    this.generateOrder = this.generateOrder.bind(this);
  }

  componentDidMount() {
    window.CollectJS.configure({
      paymentSelector: "#payButton",
      variant: "inline",
      styleSniffer: true,
      callback: (token) => {
        console.log("Se ejecuta el pago", token);
        this.setState({ isSubmitting: true });
        this.finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: "0000 0000 0000 0000",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "MM / YY",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "***",
          selector: "#cvv",
        },
      },
      validationCallback: (field, status, message) => {
        console.log(
          `Detalles: Stados ${status} Mensaje: ${message} y Field: ${field}`
        );
        if (status) {
          if (field == "ccnumber") {
            console.log("Error en el numero de Tarjeta");
          }
          if (field == "ccexp") {
            console.log("Error fecha de expiracion");
          }
          if (field == "ccv") {
            console.log("error CCV");
          }
        }
      },
    });
  }

  async generateOrder(payload) {
    this.setState({ isLoading: true });
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    fetch(`https://backzoom.herokuapp.com/create_order`, options)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        this.setState({ isLoading: false });
        this.props.onClick();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("Error");
      });
  }

  finishSubmit(response) {
    this.setState({ isSubmitting: true });
    this.setState({ isLoading: true });
    const { isSubmitting, alertMessage, ...formData } = { ...this.state };
    formData.token = response.token;
    const payload = {
      token: response.token,
      meeting_id: parseInt(this.props.meetingNumber),
      dataOrder: {
        affiliateId: this.props.affiliateId,
        subtotal: this.props.amount,
        total: this.props.amount,
        email: this.props.email,
        paymentMethod: "Credit Card",
        createDate: new Date(),
        items: this.props.items,
        shippingPerson: {
          name: this.props.name,
          companyName: "Zoom",
          street: this.props.street,
          city: this.props.city,
          countryCode: this.props.countryCode,
          postalCode: this.props.postalCode,
          stateOrProvinceCode: this.props.stateOrProvinceCode,
          phone: this.props.phone,
        },
        shippingOption: {
          shippingMethodName: "UPS - Shipping",
          shippingRate: 10,
          fulfillmentType: "SHIPPING",
        },
      },
    };
    console.log(formData);
    this.setState({
      isSubmitting: false,
    });
    this.generateOrder(payload);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    console.log("Enviando el Pago:", this.setState.isSubmitting);
    window.CollectJS.startPaymentRequest();
  }

  render() {
    return (
      <div className="App">
        <Box className="payment-form-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              padding: "10px 0px",
              width: "100%",
              justifyContent: "space-between",
              background: "#F1F1F1",
              padding: "10px",
              borderBottom: "1px solid #d5d5d5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <CreditCardOutlinedIcon fontSize="large" />
              <Typography variant="h5" fontWeight="500">
                Pay with Credit Card
              </Typography>
            </Box>
            <Box>
              <img src="https://i.imgur.com/KtUNWSn.png" width="200px" />
            </Box>
          </Box>

          {this.state.alertMessage && (
            <div className="alert">{this.state.alertMessage}</div>
          )}
          <form
            onSubmit={this.handleSubmit}
            style={{ padding: "0px 10px 10px 10px" }}
          >
            <CollectJSSection />
            <Box className="checkout-working-live-btn-checkout">
              <button
                id="payButton"
                type="submit"
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? (
                  <Typography variant="h5" fontWeight="bold">
                    Loading...
                  </Typography>
                ) : (
                  <Typography variant="h5" fontWeight="bold">
                    Send Pay
                  </Typography>
                )}
              </button>
            </Box>
          </form>
        </Box>
      </div>
    );
  }
}

export default InlineCartPage;
