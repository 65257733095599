import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import PaymentForm from "../../components/PayCreditCard/PaymentForm";
import InlineCartPageSub from "../../components/CollectJsSection/InlineCartFormSub";
import { Footer } from "../../components/Footer/Footer";
import "./index.scss";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { PlanCard } from "../../components/PlanCard/PlanCard";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

const Payments = () => {
  const { user, isAuthenticated } = useAuth0();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState(0);
  const [username, setUsername] = useState("");
  const [state, setState] = useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className="payment-container-page">
      <Navbar></Navbar>
      <Box className="form-content">
        <Box className="form-content-payment-title">
          <Typography variant="h2" fontWeight="bold">
            <span style={{ color: "#B42560" }}>Welcome!</span> Choose your
            favorite plan
          </Typography>
          <Typography variant="h5" fontWeight="500">
            We are pleased to have you here, to continue you must choose one of
            the available plans.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <PlanCard planTitle="WorkingLive Shopping" planTotal={5}></PlanCard>
          <PlanCard
            planTitle="WorkingLive Shopping Max"
            planTotal={17.99}
            max={true}
            selected={true}
          ></PlanCard>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Box className="sell-twelve">
            <img
              style={{
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "fixed",
                borderRadius: "5px",
                filter: "brightness(40) invert(-20)",
                opacity: "0.5",
              }}
              src="https://i.imgur.com/ctcJvOw.png"
            ></img>
            <Box style={{ position: "fixed" }}>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                The Original WorkingLive Webinar
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Unlimited Meeting Times
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Webinar with 500 people
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Box className="form-content-first-box">
          <Box style={{ borderBottom: "1px solid #000", width: "100%" }}>
            <Typography variant="h3" fontWeight="bold">
              Payment Form
            </Typography>
          </Box>
          <Box className="payment-form-fields">
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ width: "49%" }}>
                <Typography variant="h5" fontWeight="bold">
                  First Name<span style={{ color: "#ff0000" }}> *</span>
                </Typography>
                <input
                  className="input-credentials"
                  type="text"
                  placeholder="ex: Richard"
                  style={{ fontSize: "16px" }}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Box style={{ width: "49%" }}>
                <Typography variant="h5" fontWeight="bold">
                  Last Name<span style={{ color: "#ff0000" }}> *</span>
                </Typography>
                <input
                  className="input-credentials"
                  type="text"
                  placeholder="ex: Venegas"
                  style={{ fontSize: "16px" }}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography variant="h5" fontWeight="bold">
                E-mail<span style={{ color: "#ff0000" }}> *</span>
              </Typography>
              <input
                className="input-credentials"
                type="email"
                placeholder="ex: myname@example.com"
                style={{ fontSize: "16px" }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography variant="h5" fontWeight="bold">
                Address Line 1<span style={{ color: "#ff0000" }}> *</span>
              </Typography>
              <input
                className="input-credentials"
                type="email"
                placeholder="ex: 305 Creek RD"
                style={{ fontSize: "16px" }}
                onChange={(e) => setAddress1(e.target.value)}
              />
            </Box>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ width: "33%" }}>
                <Typography variant="h5" fontWeight="bold">
                  State<span style={{ color: "#ff0000" }}> *</span>
                </Typography>
                <input
                  className="input-credentials"
                  type="email"
                  placeholder="ex: TN"
                  style={{ fontSize: "16px" }}
                  onChange={(e) => setState(e.target.value)}
                />
              </Box>
              <Box style={{ width: "33%" }}>
                <Typography variant="h5" fontWeight="bold">
                  City<span style={{ color: "#ff0000" }}> *</span>
                </Typography>
                <input
                  className="input-credentials"
                  type="email"
                  placeholder="ex: Sevierville"
                  style={{ fontSize: "16px" }}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Box>
              <Box style={{ width: "33%" }}>
                <Typography variant="h5" fontWeight="bold">
                  Zip<span style={{ color: "#ff0000" }}> *</span>
                </Typography>
                <input
                  className="input-credentials"
                  type="email"
                  placeholder="ex: 37645"
                  style={{ fontSize: "16px" }}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Box>
            </Box>

            <Box style={{ width: "100%" }}>
              <Typography variant="h5" fontWeight="bold">
                Username<span style={{ color: "#ff0000" }}> *</span>
              </Typography>
              <input
                className="input-credentials"
                type="email"
                placeholder="ex: myusername"
                style={{ fontSize: "16px" }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography variant="h5" fontWeight="bold">
                Phone Number<span style={{ color: "#ff0000" }}> *</span>
              </Typography>
              <input
                className="input-credentials"
                type="tel"
                placeholder="000 000 0000"
                style={{ fontSize: "16px" }}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Box>
          </Box>
        </Box>
        <Box className="form-content-second-box">
          <InlineCartPageSub
            firstName={firstName}
            lastName={lastName}
            email={email}
            phoneNumber={phoneNumber}
            city={city}
            address1={address1}
            zip={zip}
            username={username}
            amount={5.99}
            state={state}
          />
        </Box>
      </Box>
      <Footer></Footer>
    </Box>
  );
};

export default Payments;
