import React from "react";
import Navbar from "../../components/navbar/Navbar";
import { Suscriptions } from "../../components/Suscriptions";
export const Plan = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Suscriptions></Suscriptions>
    </div>
  );
};
