import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./index.scss";

export const CardDetailMeeting = (props) => {
  return (
    <Box className="header-detail-meeting">
      <Box className="header-detail-meeting-text">
        <Typography variant="h5">{props.startDate}</Typography>
        <Typography variant="h6" style={{ color: "#B3B3B3" }}>
          {props.startTime}
        </Typography>
      </Box>
      <Box className="header-detail-meeting-text">
        <Typography variant="h5">{props.meetingTopic}</Typography>
        <a
          href={`${window.location.origin}/${props.meetingID}`}
          target="_blank"
        >
          <Typography variant="h6" sx={{ color: "#428bca" }}>
            {" "}
            Meeting ID: {props.meetingID}{" "}
          </Typography>
        </a>
      </Box>
      <Box className="meeting-tab-options">
        <Button className="share-meeting-tab">Share</Button>
        <Button className="edit-meeting-tab">Edit</Button>
        <Button className="delete-meeting-tab">Delete</Button>
      </Box>
    </Box>
  );
};
