import React from "react";
import { Box, Button, Typography } from "@mui/material";
import "./index.scss";

export const ContentMeetings = (props) => {
  return (
    <Box className="header-create-meeting">
      <Box className="header-create-meeting-text">
        <Typography variant="h5">Create a new Zoom shopping meeting</Typography>
        <Typography variant="h6" style={{ color: "#B3B3B3" }}>
          You haven't had a meeting before.
        </Typography>
      </Box>
      <Box>
        <Button className="start-meeting-tab" onClick={props.onClick}>
          Set Up New Meeting
        </Button>
        <Button
          className="invite-meeting-tab"
          onClick={props.onInvite}
          style={{ marginLeft: "5px" }}
        >
          Invite
        </Button>
      </Box>
    </Box>
  );
};
