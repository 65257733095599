import { useState } from "react";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./Topbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { isMobile } from "react-device-detect";

const Layout = ({ children, title }) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} title={title} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            {children}
          </main>
        </div>
        <Footer />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Layout;
